import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Наши услуги в Академии Контент-Мастерства
			</title>
			<meta name={"description"} content={"Раскройте секреты мастерства – Вместе с нами на пути к цифровому совершенству"} />
			<meta property={"og:title"} content={"Our Services | Наши услуги в Академии Контент-Мастерства"} />
			<meta property={"og:description"} content={"Раскройте секреты мастерства – Вместе с нами на пути к цифровому совершенству"} />
			<meta property={"og:image"} content={"https://niftyxa.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://niftyxa.com/img/325235.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://niftyxa.com/img/325235.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://niftyxa.com/img/325235.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://niftyxa.com/img/325235.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://niftyxa.com/img/325235.png"} />
			<meta name={"msapplication-TileImage"} content={"https://niftyxa.com/img/325235.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-1">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 30px 0px"
				font="normal 600 56px/1.2 --fontFamily-serifGaramond"
				width="60%"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				md-margin="0px 0px 40px 0px"
			>
				Основные направления наших услуг
			</Text>
			<Text margin="0px 0px 60px 0px" font="normal 500 22px/1.2 --fontFamily-serifGaramond" text-align="left" md-margin="0px 0px 40px 0px">
			В Академии Контент-Мастерства мы предлагаем широкий спектр услуг, направленных на развитие ваших навыков в сфере цифрового контента. От копирайтинга до SMM, мы готовы поделиться знаниями и опытом, чтобы помочь вам достичь новых высот.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image src="https://niftyxa.com/img/4.jpg" display="block" max-width="100%" margin="0px 0px 25px 0px" />
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
					Копирайтинг
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Освойте искусство создания текстов, которые продают, вдохновляют и вовлекают аудиторию.
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Научитесь планировать и реализовывать стратегии контент-маркетинга, которые привлекут и удержат внимание вашей аудитории.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image src="https://niftyxa.com/img/5.jpg" display="block" max-width="100%" margin="0px 0px 25px 0px" />
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
					SMM
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Узнайте, как эффективно использовать социальные сети для продвижения брендов и идей.
					</Text>
					
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image src="https://niftyxa.com/img/6.jpg" display="block" max-width="100%" margin="0px 0px 25px 0px" />
					<Text margin="0px 0px 15px 0px" font="normal 600 28px/1.2 --fontFamily-serifGaramond" text-align="center">
					Управление контентом
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						text-align="center"
						font="--base"
						padding="0px 35px 0px 35px"
						lg-padding="0px 0 0px 0"
					>
						Научитесь планировать и реализовывать стратегии контент-маркетинга, которые привлекут и удержат внимание вашей аудитории.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});